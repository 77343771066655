import React, {useState, useRef} from 'react'
import { useTranslation } from 'react-i18next';
import "./Contact.css";
import parse from "html-react-parser";

const Contact = () => {
    const { t } = useTranslation();
    const formRef = useRef(null)
    const scriptUrl = "https://script.google.com/macros/s/AKfycbw1aXI7EYJ9xsL48i0-VjxL-H7ftqC-SX4b-1jFYHrxtzl0wFaZnJ0k9CJQXDKIp76-/exec"
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)

        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current),

        }).then(res => {
            alert('Je bericht is verzonden');
            setLoading(false)
        })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <h1>{parse(t('labels.send_us_a_message'))}:</h1>
            <form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
                <div className="form-style">
                    <input type="" name="Name" placeholder={parse(t('labels.your_name'))} />
                </div>
                <div className="form-style">
                    <input type="email" name="Email" placeholder={parse(t('labels.your_email'))} />
                </div>
                <div className="form-style">
                    <textarea name="Message" cols={100} rows={6} placeholder={parse(t('labels.send_us_a_message'))} />
                </div>
                <div className="form-style">
                    <input type="submit" value={loading ? parse(t('labels.busy')) : parse(t('labels.send'))} />
                </div>
            </form>
        </div>
    )
}

export default Contact