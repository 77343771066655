import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'nl',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            nl: {
                translation: {
                    menu: {
                        home: 'Home',
                        agenda: 'Agenda',
                        aanmelden: 'Aanmelden',
                        diner: 'Diner',
                        cadeau: 'Cadeau',
                        bruiloftacts: 'Bruiloft acts',
                        contact: 'Contact',
                        vervoer: 'Vervoer'
                    },
                    labels: {
                        your_name: "Je naam *",
                        your_email: "Je emailadres *",
                        present: "Ja, ik ben aanwezig",
                        not_present: "Nee, ik ben niet aanwezig",
                        nr_adults: "Aantal volwassenen",
                        nr_children: "Aantal kinderen",
                        busy: "Bezig",
                        send: "Verzenden",
                        send_us_a_message: "Stuur ons een berichtje"
                    },
                    page: {
                        home: '<p>Geluk vermeerdert als je het deelt.</p><p>Daarom hopen wij dat jullie aanwezig willen zijn op onze bruiloft op 22 september 2023 op Vlieland.</p><p>In de 19 jaar dat wij samen zijn hebben wij mooie reizen gemaakt, maar Vlieland heeft een heel speciale plek in ons hart met veel mooie herinneringen. Daar willen we graag een hele mooie herinnering aan toevoegen, onze bruiloft. Wij hopen van harte dat jullie deze dag met ons mee willen beleven.</p><p><strong><a href="https://mariebroeckmanphotography.pic-time.com/-annarobertsneak/gallery">Klik hier voor de foto\'s van de bruiloft</a></strong></p>',
                        diner: '<p>Willen jullie op deze pagina: <a href="https://forms.gle/DLELG5rr8wrKu1CV8" target="_blank">https://forms.gle/DLELG5rr8wrKu1CV8</a> de keuze voor het eten aan ons doorgeven?</p>' +
                            '<p>Voor de kinderen hebben wij een avondprogramma. Zij eten van 19.00-20.00 uur en gaan daarna  met Kike en Puk naar de Juttersbar in het aangrenzende hotel waar allerlei dingen voor kinderen te doen zijn en/of ze gaan naar het strand tot 22.00. Kike en Puk zijn twee oud-leerlingen van Anna. Kike heeft net de Pabo afgerond en zit vol idee&euml;n wat ze met de kinderen kan doen. Puk komt haar hierbij helpen.</p>' +
                            '<p>Mochten jullie in Hotel Seeduyn overnachten, vraag dan even naar de oppasmogelijkheden binnen het hotel.</p>',
                        cadeau: '<p>Wij vinden het al een cadeau dat jullie zoveel moeite willen doen om aanwezig te zijn op onze bruiloft. Een cadeau op zich!</p>' +
                            '<p>Mocht je ons een cadeau willen geven dan zouden wij heel erg blij zijn met een bijdrage voor een giclée van Ellen Davidzon: <a href="https://ellendavidzon.nl/product/jump-for-joy">ellendavidzon.nl/product/jump-for-joy</a>. </p>',
                        agenda: '<p>Vrijdag 22 september zullen wij trouwen. Dit is ongeveer het schema van de dag:\n' +
                            '        <ul>\n' +
                            '            <li>14:30 - 15:00: Kerk geopend (Voor wie wil met rondleiding door de kerk)</li>\n' +
                            '            <li>15:00 - 16:00: Trouwerij in Nicolaaskerk</li>\n' +
                            '            <li>16:15 - 18:00: Toasten bij De Wadden</li>\n' +
                            '            <li>19:00 - 22:00: Diner in Strandpaviljoen ’t Badhuys</li>\n' +
                            '            <li>22:00 - 02:00: Feest in Strandpaviljoen ’t Badhuys</li>\n' +
                            '        </ul><br />' +
                            '* De gehele dag is er een fotograaf aanwezig. Foto\'s maken met de mobiele telefoon is dus niet nodig.</p>',
                        bruiloftacts: '<p>Roberts tante Mariska, zou met haar talent een avondvullend programma kunnen geven, maar ze laat ook graag ruimte voor anderen om hun talent aan ons te tonen.<br /><br/>' +
                            'Wil je graag iets doen voor ons? Heel erg leuk! Laat het even weten aan onze ceremoniemeester Maartje Boers door haar te mailen: <a href="mailto:schrijfmaartje@gmail.com">schrijfmaartje@gmail.com</a>. Zij zal in overleg met jullie een programma maken.\n</p>',
                        vervoer: '<p>Zodra je op de boot naar Vlieland stapt, stap je letterlijk een andere wereld in. De reis over zee is al de start van een paar dagen weg. Rederij Doeksen vaart dagelijks een aantal keren naar het eiland. Om vrijdag op tijd bij de huwelijksinzegening te zijn moeten jullie uiterlijk de boot van 09.05 op 22 september nemen. Deze veerdienst duurt ca.95 minuten. Bij aankomst op Vlieland hebben jullie de tijd om in te checken en je om te kleden als je dat wilt. Boek op tijd de boot, Vlieland is het hele jaar door een geliefde bestemming en het kan onverwacht druk zijn op de boot.</p>' +
                            '<h2>Parkeren</h2>' +
                            '<p>Vlieland is een autovrij eiland. De auto kun je parkeren bij de haven. <a href="https://www.parkerenharlingen.nl/parkeerlocaties/terschelling-vlieland" target="_blank">https://www.parkerenharlingen.nl/parkeerlocaties/terschelling-vlieland</a>. Vanaf de parkeerplaats gaat een bus naar de terminal bij de haven. Mocht je liever willen lopen? Kan uiteraard ook, duurt ongeveer 15 minuten.</p>' +
                            '<p>Op het eiland zelf kun je je bagage afgeven bij de busjes die bij de veerterminal klaar staan. Zij brengen al jullie bagage meteen naar jullie verblijf. Dat is absoluut een aanrader en scheelt veel gesleep.</p>' +
                            '<h2>Trein</h2>' +
                            '<p>Wil je met de trein komen, neem dan de trein naar Harlingen Haven. De veerterminal is 5 minuten lopen van het station vandaan.</p>' +
                            '<h2>Fietsen</h2>' +
                            '<p>Fietsen over Vlieland is een feestje op zich. In het dorp zijn meerdere fietsverhuur bedrijven. Je kunt elke mogelijke fiets huren. Dat kan ter plekke, maar ook online. Dan staat de fiets klaar bij aankomst.<br />' +
                            '<a href="https://janvanvlieland.nl" target="_blank">janvanvlieland.nl</a><br />' +
                            '<a href="https://fietsverhuurvlieland.nl" target="_blank">fietsverhuurvlieland.nl</a><br />' +
                            '<a href="https://www.fietsverhuur-frisia.nl" target="_blank">fietsverhuur-frisia.nl</a></p>'
                    }
                }
            },
            de: {
                translation: {
                    menu: {
                        home: 'Home',
                        agenda: 'Programm',
                        aanmelden: 'Anmelden',
                        overnachting: 'Übernachtung',
                        diner: 'Diner',
                        cadeau: 'Cadeau',
                        bruiloftacts: 'Hochzeitacts',
                        contact: 'Kontakt',
                        vervoer: 'Reise '
                    },
                    labels: {
                        your_name: "Dein Name *",
                        your_email: "Deine Emailadresse *",
                        present: "Ja, ich bin anwesend",
                        not_present: "Nein, ich bin nicht anwesend",
                        nr_adults: "Zahl der Erwachsenen",
                        nr_children: " Zahl der Kinder",
                        busy: "Moment",
                        send: "Senden",
                        send_us_a_message: "Sende uns eine Nachricht"
                    },
                    page: {
                        home: '<p>Glück vermehrt wenn man es teilt. Deswegen hoffen wir, dass ihr am 22. September 2023 anwesend sein könnt auf unserer Hochzeit auf Vlieland.</p><p>In den 19 Jahren die wir zusammen sind haben wir viele schöne Reisen gemacht, aber Vlieland hat einen besonderen Platz in unserem Herz mit vielen schönen Erinnerungen. Wir möchten zusammen mit euch eine sehr wertvolle Erinnerung hinzufügen, unsere Hochzeit. Wir hoffen von ganzem Herzen, dass ihr diesen Tag mit uns erleben möchtet.</p><p>Auf dieser Website stehen einige Informationen die wir, sobald wir Neuigkeiten haben, einen Update geben werden.</p><p><strong><a href="https://mariebroeckmanphotography.pic-time.com/-annarobertsneak/gallery">Klicken Sie hier für die Fotos der Hochzeit</a></strong></p>',
                        overnachting: '<p>Hier könnt ihr Information finden über die Übernachtungsmöglichkeiten auf Vlieland. Die Insel ist klein, so auch der Abstand zwischen dem Dorf und dem Meer. Das Essen und das Fest finden im “ Het Badhuys” statt, das Restaurant am Ende des Badweges. Das Hotel, das am nähesten dran liegt ist das “Strandhotel Seeduyn”.  Wenn ihr dort übernachten möchtet bekommt ihr 10% Rabatt wenn ihr auf unserer “Landungsseite”klickt. Natürlich gibt es mehrere Übernachtungsmöglichkeiten wie zum Beispiel Ferienhäuser, Appartements und Hotels im Dorf. Um die Suche zu vereinfachen haben wir einige Sites hinzugefügt.</p>' +
                            '<a href="https://westcordhotels.nl/bruiloft-robert-en-anna/" target="_blank">westcordhotels.nl/bruiloft-robert-en-anna/</a><br />' +
                            '<p><a href="https://www.op-vlieland.nl" target="_blank">www.op-vlieland.nl</a><br />' +
                            '<a href="https://www.vlieland.net" target="_blank">www.vlieland.net</a><br />' +
                            '<a href="https://www.bungalowverhuurzevenster.nl" target="_blank">www.bungalowverhuurzevenster.nl</a></p>',
                        diner: '<p>Könntet ihr auf dieser Seite euere Menüwahl aussuchen?: <a href="https://forms.gle/M2eqEU6DnrvEabAV6" target="_blank">https://forms.gle/M2eqEU6DnrvEabAV6</a></p>',
                        cadeau: '<p>Ihr bekommt demnächst von uns die Menüwahl. Wir werden es hier auf der Website schreiben.</p>',
                        aanmelden: '<p>Wärt ihr so lieb hier anzugeben ob ihr anwesend sein wird und mit wieviele Personen ihr kommt?</p>',
                        bruiloftacts: '<p>Wenn ihr gerne etwas sagen möchtet auf der Hochzeit, schriebt dann bitte Maartje Boers eine Email: <a href="mailto:schrijfmaartje@gmail.com">schrijfmaartje@gmail.com.</a><br /><br />' +
                            'Fühlt euch keineswegs verpflichtet! Das ihr da seid ist schon ein Geschenk.</p>',
                        agenda: '<p><ul><li>15.00- 16.00 Hochziet in der Nikolaaskirche</li>' +
                            '<li>16.15- 18.00 ein Toast im Hotel de Wadden</li>' +
                            '<li>19.00-22.00 Diner im Badhuys</li>' +
                            '<li>22.00-02.00 Fest im Badhuys</li></ul></p>',
                        vervoer: '<p>Sobald ihr den ersten Schritt auf dem Schiff nach Vlieland macht, seid ihr in einer ganz anderen Welt. Alleine schon die Reise über das Wattenmeer ist der Anfang eines Urlaubes. Rederij Doeksen fährt mehrmals täglich nach Vlieland. Um am Freitag den 22. September zeitig bei der Trauung anwesend zu sein, solltet ihr die Abfahrt um 09.05 nehmen. Die Fahrt dauert ungefähr 95 Minuten. Bei der Ankunft auf Vlieland habt ihr genügend Zeit einzuchecken und euch umzukleiden wenn ihr wünscht. Bucht die Abfahrt zeitig, Vlieland ist das ganze Jahr ein beliebter Urlaubsort.</p>' +
                            '<h2>Parken</h2>' +
                            '<p>Vlieland ist eine autofreie Insel. Euer Auto könnt ihr parken am Hafen. https://www.parkerenharlingen.nl/parkeerlocaties/terschelling-vlieland. Am Parkplatz fährt ein Bus zum Hafenterminal. Ihr könnt auch laufen wenn ihr möchtet, die Laufzeit beträgt ungefähr 15 Minuten.</p>' +
                            '<p>Auf Vlieland könnt ihr euer Gepäck abgeben bei den Bussen die beim Hafenterminal stehen. Sie bringen euer Gepäck sofort nach euerer Ferienwohnung. Sehr empflehlenswert, da ihr nicht selber euer Gepäck tragen braucht.</p>' +
                            '<h2>Zug</h2>' +
                            '<p>Falls ihr mit dem Zug kommt, nimmt dann den Zug nach Harlingen Haven. Der Terminal ist 5 Minuten laufen von dem Bahnhof entfernt.</p>' +
                            '<h2>Fahrrad fahren</h2>' +
                            '<p>Fahrrad fahren auf Vlieland macht unglaublich viel Spaβ. Im Dorf gibt es verschiedene Stellen wo ihr allerhand Fahrräder mieten könnt. Das könnt ihr spontan machen aber auch im voraus online. Das Fahrrad steht dann bereit bei eurer Ankunft.<br />' +
                            '<a href="https://janvanvlieland.nl" target="_blank">janvanvlieland.nl</a><br />' +
                            '<a href="https://fietsverhuurvlieland.nl" target="_blank">fietsverhuurvlieland.nl</a><br />' +
                            '<a href="https://www.fietsverhuur-frisia.nl" target="_blank">fietsverhuur-frisia.nl</a></p>' +
                            '<h2>Bus</h2>' +
                            '<p>Obwohl Vlieland autofrei ist, fährt ein Bus. Der Bus steht bei Ankunft am Terminal bereit und fährt euch nach jeder Haltestelle auf Vlieland. Falls laufen ein Problem ist, gibt es auch ein Taxi auf der Insel.</p>'
                    }
                }
            }
        }
    });

export default i18n;