import React from "react";
import { useTranslation } from 'react-i18next';
import parse from "html-react-parser";

const Agenda = () => {
    const { t } = useTranslation();

    return <div>
        <h1>{parse(t('menu.agenda'))}</h1>
        {parse(t('page.agenda'))}
    </div>;
};

export default Agenda;