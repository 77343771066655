import { BrowserRouter, Routes, Route} from 'react-router-dom'
import React from 'react';
import Navbar from './Navbar'
import Slider from './SimpleSlider'
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Aanmelden from "./pages/Aanmelden";
import Cadeau from "./pages/Cadeau";
import Diner from "./pages/Diner";
import Bruiloftacts from "./pages/Bruiloftacts";
import Overnachting from "./pages/Overnachting";
import Agenda from "./pages/Agenda";
import Vervoer from "./pages/Vervoer";
import "./App.css";

function App() {

    return (
        <div>
            <BrowserRouter>
                <Navbar />
                <div className="content">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/agenda" element={<Agenda />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/aanmelden" element={<Aanmelden />} />
                        <Route path="/diner" element={<Diner />} />
                        <Route path="/cadeau" element={<Cadeau />} />
                        <Route path="/bruiloftacts" element={<Bruiloftacts />} />
                        <Route path="/overnachting" element={<Overnachting />} />
                        <Route path="/vervoer" element={<Vervoer />} />
                    </Routes>
                </div>
            </BrowserRouter>
            <footer>
                <Slider />
            </footer>
        </div>
    )
}

export default App;