import React from "react";
import { useTranslation } from 'react-i18next';
import parse from "html-react-parser";

const Overnachting = () => {

    const { t } = useTranslation();

    return <div>
        <h1>{parse(t('menu.overnachting'))}</h1>
        {parse(t('page.overnachting'))}
    </div>;
};

export default Overnachting;