import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Hamburger } from './assets/icons/hamburger.svg'
import LangSwitch from "./LangSwitch";
import './Navbar.css'
import "./i18n";
import {useTranslation} from "react-i18next";

const Navbar = () => {
    const { t } = useTranslation();
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    const hideNavbar = () => {
        setShowNavbar( false);
    }

    const formatMenuUrl = item => {
        return item
    }

    return (
        <nav className="navbar">
            <div className="container">
                <LangSwitch />
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <Hamburger className="hamburger" width={20} />
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <ul onClick={hideNavbar}>
                        <li>
                            <NavLink to={formatMenuUrl("")}>{t('menu.home')}</NavLink>
                        </li>
                        <li>
                            <NavLink to={formatMenuUrl("agenda")}>{t('menu.agenda')}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/vervoer">{t('menu.vervoer')}</NavLink>
                        </li>

                        <li>
                            <NavLink to="/diner">{t('menu.diner')}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/cadeau">{t('menu.cadeau')}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/bruiloftacts">{t('menu.bruiloftacts')}</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">{t('menu.contact')}</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
