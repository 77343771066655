import React from "react";
import { useTranslation } from 'react-i18next';
import parse from "html-react-parser";

const Diner = () => {
    const { t } = useTranslation();

    return <div>
        <h1>{parse(t('menu.diner'))}</h1>
        {parse(t('page.diner'))}
    </div>;
};

export default Diner;