import React from "react"
import nlFlag from "./assets/flags/nl.svg"
import deFlag from "./assets/flags/de.svg"
import './LangSwitch.css'
import "./i18n";
import {useTranslation} from "react-i18next";

const LangSwitch = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="langSwitch">
            <img src={nlFlag} alt="Nederlands" height={16} onClick={() => i18n.changeLanguage("nl")} />
            &nbsp;
            <img src={deFlag} alt="Deutsch" height={16} onClick={() => i18n.changeLanguage("de")} />
        </div>
    );
};

export default LangSwitch;