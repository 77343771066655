import React, {useState, useRef} from 'react'
import { useTranslation } from 'react-i18next';
import parse from "html-react-parser";

const Aanmelden = () => {
    const { t } = useTranslation();

    const formRef = useRef(null)
    const scriptUrl = "https://script.google.com/macros/s/AKfycbwwoQulc0BoADM9yv7uXYmeIbgTabWzuBdbUaHfa3trxxA3a1V_yZxYGEWulU_S0roeTg/exec"
    const [loading, setLoading] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault()
        setLoading(true)


        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current),

        }).then(res => {
            setLoading(false);
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
            );
            alert('Je aanmelding is verzonden');

        })
            .catch(err => console.log(err))
    }

    return <div>
        <h1>{parse(t('menu.aanmelden'))}</h1>
        {parse(t('page.aanmelden'))}
        <form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
            <div className="form-style">
                <input type="text" name="Name" placeholder={parse(t('labels.your_name'))}/>
            </div>
            <div className="form-style">
                <input type="text" name="Email" placeholder={parse(t('labels.your_email'))} />
            </div>
            <div className="form-style">
                <input type="radio" name="Aanwezig" value="Ja" defaultChecked={true} /> {parse(t('labels.present'))}

                <input type="radio" name="Aanwezig" value="Nee" /> {parse(t('labels.not_present'))}
            </div>
            <div>
                <select name="Aantal_Volwassenen">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                </select>
                &nbsp;
                {parse(t('labels.nr_adults'))}
                &nbsp;&nbsp;
                <select name="Aantal_Kinderen">
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                </select>
                &nbsp;{parse(t('labels.nr_children'))}
            </div>
            <br />
            <div className="form-style">
                <textarea name="Message" cols={100} rows={6} placeholder={parse(t('labels.send_us_a_message'))} />
            </div>
            <div className="form-style">
                <input type="submit" value={loading ? parse(t('labels.busy')) : parse(t('labels.send'))} />
            </div>
        </form>
    </div>;
};

export default Aanmelden;