import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class SimpleSlider extends Component {


    render() {
        const settings = {
            dots: false,
            infinite: true,
            initialSlide: Math.floor(Math.random() * 10),
            speed: 500,
            centerMode: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div>
                <Slider {...settings}>
                    <div>
                        <img src="images/sela-seppe-boaz.jpg" />
                    </div>
                    <div>
                        <img src="images/robert-anna-2.jpg" />
                    </div>
                    <div>
                        <img src="images/anna-boaz-sela.jpg" />
                    </div>
                    <div>
                        <img src="images/robert-sela-2.jpg" />
                    </div>
                    <div>
                        <img src="images/robert-anna-3.jpg" />
                    </div>
                    <div>
                        <img src="images/anna-robert-lapland.jpg" />
                    </div>
                    <div>
                        <img src="images/robert-sela-anna-moos.jpg" />
                    </div>
                    <div>
                        <img src="images/robert-sela.jpg" />
                    </div>
                    <div>
                        <img src="images/anna-boaz.jpg" />
                    </div>
                </Slider>
            </div>
        );
    }
}